import { useState, useMemo } from "react";
import Footer from "./components/Footer";
import CountDown from "./pages/CountDown";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider } from "wagmi";
import { sepolia } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App(): JSX.Element {
  // 0. Setup queryClient
  const projectId = "e0fb9e301fe36fc3e2392758c7b9fa28";

  const queryClient = useMemo(() => new QueryClient(), []);

  const chains = useMemo(
    () =>
      [
        {
          ...sepolia,
          rpcUrls: {
            default: {
              http: [
                "https://eth-sepolia.g.alchemy.com/v2/pVkWDO4Y29fdEVbAz4a0BsGHR5F91cMq",
              ],
            },
          },
        },
      ] as const,
    []
  );

  const metadata = useMemo(
    () => ({
      name: "Web3Modal",
      description: "Web3Modal Example",
      url: "https://web3modal.com", // origin must match your domain & subdomain
      icons: ["https://avatars.githubusercontent.com/u/37784886"],
    }),
    []
  );

  const config = useMemo(() => {
    console.log("Creating WagmiConfig");
    return defaultWagmiConfig({
      projectId,
      chains,
      metadata,
    });
  }, [projectId, chains, metadata]);

  useMemo(() => {
    console.log("Creating Web3Modal");
    return createWeb3Modal({
      wagmiConfig: config,
      projectId,
      enableAnalytics: true, // Optional - defaults to your Cloud configuration
      enableOnramp: true, // Optional - false as default
    });
  }, [projectId, config]);

  // const targetDate = useMemo(() => new Date(2024, 2, 30, 0, 0, 0), []); //  March 30th, 2024, 12:00:00 AM
  const [targetDate] = useState<Date>(new Date(Date.now() + 20 * 1000)); // 20 seconds

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <div className="p-[20px] sm:p-[40px] md:p-[70px] xl:p-[100px] max-w-screen-2xl mx-auto">
          <CountDown key={123} endDate={targetDate} />
          <Footer />
        </div>
        <ToastContainer />
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
