import React from "react";

import goNextLogo from "../assets/picture/goNext.svg";

import twitterLogo from "../assets/picture/TwitterLogo.svg";
import discordLogo from "../assets/picture/DiscordLogo.svg";
import githubLogo from "../assets/picture/GithubLogo.svg";
import telegramLogo from "../assets/picture/TelegramLogo.svg";
import linkedinLogo from "../assets/picture/LinkedinLogo.svg";

const Footer: React.FC = () => {
  return (
    <div>
      <div className="flex flex-col lg:flex-row justify-between mt-[100px] lg:mt-[200px] gap-[70px]">
        {/* Footer content */}
        <div className="flex flex-col gap-y-[30px] lexend-500 max-w-[300px]">
          <span className="text-[#9A9898] text-[16px]">
            Subscribe for fresh content & product updates
          </span>
          <div className="relative flex flex-row items-center outline-none bg-[#E6E5E5] rounded-full px-[24px] py-[10px]">
            <input
              type="text"
              className="bg-transparent text-[16px] placeholder:text-[#9A9898] outline-none"
              placeholder="Your email"
            />
            <img src={goNextLogo} alt="" className="absolute right-[4px]" />
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-[30px] text-[16px]">
          {/* Footer links */}
          {/* Replace placeholder links with actual ones */}
          <div className="flex flex-col gap-y-[20px]">
            <span className="text-[14px] text-[#9A9898]">website</span>
            <span className="cursor-pointer">Home</span>
            <span className="cursor-pointer">For Developers</span>
            <span className="cursor-pointer">For Validators</span>
            <span className="cursor-pointer">F.A.Q.</span>
          </div>

          <div className="flex flex-col gap-y-[20px]">
            <span className="text-[14px] text-[#9A9898]">
              Developer resources
            </span>
            <span className="cursor-pointer">Bug Bounty Program</span>
            <span className="cursor-pointer">Documentation</span>
            <span className="cursor-pointer">Cosmos block explorer</span>
            <span className="cursor-pointer">EVM block explorer</span>
            <span className="cursor-pointer">Faucet</span>
          </div>

          <div className="flex flex-col gap-y-[20px]">
            <span className="text-[14px] text-[#9A9898]">Apps</span>
            <span className="cursor-pointer">App Store</span>
            <span className="cursor-pointer">Google Play</span>
          </div>

          <div className="flex flex-col gap-y-[20px]">
            <span className="text-[14px] text-[#9A9898]">Legal</span>
            <span className="cursor-pointer">Whitepaper</span>
            <span className="cursor-pointer">Legal Documents</span>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-center gap-x-[5px] text-[#0D0C0C] text-[32px] xs:text-[44px] sm:text-[60px] md:text-[80px] lg:text-[100px] xl:text-[140px] 2xl:text-[180px] lexend-600 mt-[100px]">
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          S
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          W
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          I
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          S
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          S
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          T
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          R
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          O
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          N
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          I
        </span>
        <span className="cursor-pointer relative transition-top top-0 ease-in duration-100 hover:text-flame hover:-top-2">
          C
        </span>
      </div>

      {/* Social media and copyright */}
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-[20px] mt-[30px]">
        <div className="flex flex-row items-center gap-x-[30px] mt-[30px]">
          <img src={twitterLogo} alt="" className="cursor-pointer" />
          <img src={linkedinLogo} alt="" className="cursor-pointer" />
          <img src={discordLogo} alt="" className="cursor-pointer" />
          <img src={telegramLogo} alt="" className="cursor-pointer" />
          <img src={githubLogo} alt="" className="cursor-pointer" />
        </div>

        <span className="text-[#9A9898]">©2023 Sigma Assets GmbH</span>
      </div>
    </div>
  );
};

export default Footer;
