import React from "react";
import Countdown from "react-countdown";
import Logo from "../components/Logo";
import Home from "./Home";

interface CountDownProps {
  endDate: Date;
}

const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return <Home />;
  } else {
    return (
      <div className="flex flex-col">
        <div className="flex flex-col justify-between lg:flex-row lg:items-center">
          <Logo />
        </div>
        <div className="w-full h-[400px] flex flex-col items-center justify-center gap-y-[10px]">
          <span className="text-[22px] md:text-[36px]">
            PUBLIC MINT WILL START IN
          </span>
          <div className="flex flex-row gap-x-[10px]">
            <div className="flex flex-row items-end gap-x-[10px]">
              <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
                {days}
              </span>
              <span className="text-[14px] leading-[10px]">DD</span>
              {/* Add similar elements for hours, minutes, and seconds */}
              <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
                {hours}
              </span>
              <span className="text-[14px] leading-[10px]">HH</span>
              <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
                {minutes}
              </span>
              <span className="text-[14px] leading-[10px]">MM</span>
              <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">
                {seconds}
              </span>
              <span className="text-[14px] leading-[10px]">SS</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const CountDown: React.FC<CountDownProps> = ({ endDate }) => {
  return <Countdown key={123} date={endDate} renderer={renderer} />;
};

export default CountDown;
