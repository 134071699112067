import React from "react";
import Marquee from "react-fast-marquee";

import { slideImages } from "../assets/helpers";

interface MarqueeProps {}

const MarqueeComponent: React.FC<MarqueeProps> = () => {
  return (
    <Marquee>
      {slideImages.map((item, index) => (
        <div className="m-[12px]" key={index}>
          <img
            src={item.image}
            alt={`Slide ${index + 1}`}
            className="w-[100px] md:w-[150px]"
          />
        </div>
      ))}
    </Marquee>
  );
};

export default MarqueeComponent;
